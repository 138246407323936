@mixin font-sizes($prefix: "") {
  .text-size#{$prefix}-extra-small {
    font-size: $font-size-xs;
  }
  .text-size#{$prefix}-small {
    font-size: $font-size-sm;
  }
  .text-size#{$prefix}-base {
    font-size: $font-size-base;
  }
  .text-size#{$prefix}-large {
    font-size: $font-size-lg;
  }
  .text-size#{$prefix}-h1 {
    font-size: $h1-font-size;
  }
  .text-size#{$prefix}-h2 {
    font-size: $h2-font-size;
  }
  .text-size#{$prefix}-h3 {
    font-size: $h3-font-size;
  }
  .text-size#{$prefix}-h4 {
    font-size: $h4-font-size;
  }
  .text-size#{$prefix}-h5 {
    font-size: $h5-font-size;
  }
  .text-size#{$prefix}-h6 {
    font-size: $h6-font-size;
  }
}
