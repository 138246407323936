// Colors
$sizzling-red: #f05d5e;
$old-rose: #c77971;
$snow: #f9f4f4;
$fuzzy-wuzzy: #bf655b;

$primary: $fuzzy-wuzzy;

// Font weight
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-black: 900;

// Font size
$font-size-xs: ($font-size-base * 0.75);
