.container-success {
  background-color: $old-rose;
}

.feedback-wave {
  position: absolute;
  left: 0;
}

img.success-books {
  width: 100%;
}

.text-footer {
  font-family: "Dala Moa", sans-serif;
}
