.text-weight-light {
  font-weight: $font-weight-light;
}
.text-weight-bold {
  font-weight: $font-weight-bold;
}
.text-weight-normal {
  font-weight: $font-weight-normal;
}
.text-weight-medium {
  font-weight: $font-weight-medium;
}
.text-weight-black {
  font-weight: $font-weight-black;
}

.text-underline {
  text-decoration: underline;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}

.text-skobeloff {
  color: $primary;
}
.text-yale-blue {
  color: $secondary;
}

.text-size-small {
  font-size: $font-size-sm;
}
.text-size-extra-small {
  font-size: $font-size-xs !important;
}
.text-size-large {
  font-size: $font-size-lg;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100vh {
  height: 100vh;
}

@include font-sizes;
@include media-breakpoint-up(sm) {
  @include font-sizes($prefix: "-sm");
}
@include media-breakpoint-up(md) {
  @include font-sizes($prefix: "-md");
}
@include media-breakpoint-up(lg) {
  @include font-sizes($prefix: "-lg");
}
@include media-breakpoint-up(xl) {
  @include font-sizes($prefix: "-xl");
}
