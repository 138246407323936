.btn-primary {
  color: $white;
  background-color: $primary !important;
  border-color: $primary !important;

  &:active {
    background-color: $primary !important;
    border-color: $primary !important;
  }

  &:hover {
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }

  &:focus {
    background-color: $primary !important;
    border-color: $primary !important;
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.5) !important;
  }
}
