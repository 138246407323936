@include media-breakpoint-down(md) {
  .feedback-wave {
    width: 90%;
  }

  .logo {
    width: 30%;
  }

  .text-footer {
    font-size: 2vw;
  }
}
