@include media-breakpoint-up(lg) {
  .container-success {
    height: 100vh;
  }

  .feedback-wave {
    width: 60%;
  }

  .logo {
    width: 25%;
  }

  .text-footer {
    font-size: 2vw;
  }
}
