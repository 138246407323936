@include media-breakpoint-down(sm) {
  .feedback-wave {
    width: 100%;
  }

  img.success-books {
    width: 85%;
  }

  .logo {
    width: 30%;
  }

  .text-footer {
    font-size: 4vw;
  }
}
