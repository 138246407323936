.form {
  p {
    font-family: "Gill Sans", sans-serif;
    font-weight: $font-weight-bold;
  }
}

.form-group {
  label {
    font-family: "Gill Sans", sans-serif;
    font-weight: $font-weight-light;
  }
  .form-check-label {
    margin-left: 0.25rem;
  }
}

.form-check-input {
  margin-top: 0.18rem;
}

.form-control {
  border-radius: 8px;
  border: none;
}

.container-select {
  position: relative;

  &:after {
    content: "\f078";
    font: normal normal normal 17px/1 FontAwesome;
    color: $old-rose;
    right: 5%;
    top: 25%;
    position: absolute;
    pointer-events: none;
  }

  select {
    appearance: none;
  }
}

.form-control--border-radius {
  border-radius: 0.5rem;
}

.form-control--transparent {
  background-color: transparent;

  &:hover,
  &:active,
  &::placeholder {
    color: $white;
    background-color: transparent;
    border-color: $white;
  }

  &:focus {
    color: $white;
    background-color: transparent;
    border-color: $white;
    box-shadow: 0 0 0 0.2rem #ffffff40;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-error-message {
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
}

.form-error-message--red {
  color: $sizzling-red;
}

.form-error-message--white {
  color: $white;
}

.form-section {
  border-bottom: 3.5px solid $fuzzy-wuzzy;
  font-weight: $font-weight-bold;
}
